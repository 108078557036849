import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import Layout from '../components/Layout'

import { graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faHome } from '@fortawesome/free-solid-svg-icons'
import Button from '../components/Button'
import Container from '../components/Container'
import {
  Label,
  Select,
  Input,
  RadioField,
} from '../components/Form/FormControls'
import { getZipFromCoords, serviceBranchSearch } from '../api'
import { geoPromise } from '../helpers'
import { motion, AnimatePresence } from 'framer-motion'
import BranchCard from '../components/BranchCard'
import useMessageTimeout from '../hooks/useMessageTimeout'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../helpers'

const BranchLoader = () => {
  const loaders = Array(8).fill('')
  return loaders.map((e, i) => (
    <div key={`branch-loader-${i}`} tw="bg-gray-300 animate-pulse h-64"></div>
  ))
}

const SearchWidget = ({ onSearch, ...remainingProps }) => {
  const [userInput, setUserInput] = useState('')

  const [errorMessage, setErrorMessage] = useState('')
  const showError = useMessageTimeout([errorMessage, setErrorMessage], 5000)

  const submitSearch = (searchTerm = userInput) => {
    serviceBranchSearch(searchTerm).then(branches => {
      if (typeof onSearch === 'function') onSearch(branches, searchTerm)
    })
  }

  const getUserLocation = async () => {
    const geoResult = await geoPromise()
      .then(({ coords }) => coords)
      .catch(error => {
        if (error.code === error.PERMISSION_DENIED) {
          // user blocked location permissions

          setErrorMessage('Enable location permissions to use this feature.')
          return 'PERMISSION NOT SET'
        }
        return error.message
      })

    if (geoResult?.latitude) {
      const { latitude: lat, longitude: lng } = geoResult
      await getZipFromCoords({ lat, lng })
        .then(res => res.json())
        .then(json => {
          json.results[0].address_components.map(component => {
            if (component.types.includes('postal_code')) {
              setUserInput(component.long_name.toString())
              submitSearch(component.long_name.toString())
            }
          })
        })
    }
  }

  return (
    <div tw="bg-gray-50 rounded-lg px-5 py-5 mb-5">
      <h2 tw="text-base font-medium text-gray-800 mb-2">Find a Location</h2>
      <div css={[tw`grid auto-cols-auto`, `grid-template-columns: auto 3rem;`]}>
        <Input
          placeholder="Enter city and state or ZIP Code"
          tw="mb-0 rounded border-gray-400 h-10 text-xs w-full sm:(text-sm)"
          pattern="/0-9/"
          inputProps={{ inputMode: 'numeric' }}
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
        />
        <Button tw="w-10 md:(w-full) ml-2" small onClick={() => submitSearch()}>
          Go
        </Button>
      </div>

      <Button
        onClick={getUserLocation}
        tw="p-0 pt-2 underline text-base text-blue-600"
        link
      >
        Use my current location
      </Button>

      <AnimatePresence>
        {showError && errorMessage.length > 0 && (
          <motion.div
            initial={tw`h-0 opacity-0`}
            animate={tw`h-auto opacity-100`}
            exit={tw`h-0 opacity-0`}
          >
            <div tw="text-sm bg-red-100 border-red-400 border rounded text-red-600 px-5 py-3">
              <p>{errorMessage}</p>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const BranchPage = ({ data, location }) => {
  const branches = data.allWpBranch.nodes.map(branch => {
    branch.types = branch.branchTypes.nodes.map(node => node.slug).join(',')
    branch.state = branch.branchMeta.branchOfficeState
    return branch
  })

  const multiPropsFilter = (array, filters) => {
    const filterKeys = Object.keys(filters)

    return array.filter(item => {
      return filterKeys.every(key => {
        if (!filters[key].trim().length) return true
        switch (key) {
          case 'slug':
            // returns multiple slugs to filter.  Check if the branch matches the results
            return filters[key].includes(item[key])
          case 'types':
            // checks if the branch type meets a single filter.
            return item[key].includes(filters[key])
          case 'state':
            // checks if the branch is in the exact US state selected
            return item[key] === filters[key]
          default:
            return false
        }
      })
    })
  }

  const updateFilters = (key, value) => {
    const newFilters = Object.assign({}, filters, { [key]: value })
    setFilters(newFilters)
  }

  const [filteredBranches, setFilteredBranches] = useState()
  const [loading, setLoading] = useState(true)

  const [filters, setFilters] = useState({
    state: '',
    types: '',
    slug: '',
  })

  useEffect(() => {
    setFilteredBranches(branches)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!filteredBranches) return

    setLoading(true)
    setFilteredBranches(multiPropsFilter(branches, filters))
    setLoading(false)
  }, [filters])

  const [searchTerm, setSearchTerm] = useState('')
  const [branchesBySearch, setBranchesBySearch] = useState([])

  useEffect(() => {
    if (searchTerm.length && branchesBySearch.length) {
      // condense all slugs into a string to handle multiple results;
      const slugs = branchesBySearch
        .map(({ node }) =>
          node?.serviceAreaMeta?.serviceAreaBranch
            .map(({ slug }) => slug)
            .join(' ')
        )
        .join(' ')

      updateFilters('slug', slugs)
    } else if (searchTerm.length) {
      updateFilters('slug', 'none')
    } else {
      updateFilters('slug', '')
    }
  }, [searchTerm])

  const clearSearch = () => setSearchTerm('')

  const title = `Locations - ${
    JSON.parse(data.wp.seo.contentTypes.post.schema.raw)[0]?.name
  }`

  return (
    <Layout location={location}>
      <Seo
        title={`Locations - ${JSON.parse(
          data.wp.seo.contentTypes.post.schema.raw
        )['@graph'][0]?.name.replace('&#039;', "'")}`}
        postSchema={formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw}
      />
      <Container tw="py-8">
        <div tw="md:(grid grid-cols-2 col-gap-8) lg:(grid-cols-3)">
          <div tw="h-full">
            <div tw="sticky top-0">
              <h1 tw="text-lg font-bold mb-4">Branch Locator</h1>

              <SearchWidget
                onSearch={(branches, userSearchTerm) => {
                  setBranchesBySearch(branches)
                  setSearchTerm(userSearchTerm)
                }}
              />
              <RadioField
                className="branch-radio"
                data={{
                  choices: [
                    {
                      text: 'residential',
                      label: (
                        <>
                          <FontAwesomeIcon tw="text-yellow-600" icon={faHome} />{' '}
                          Residential
                        </>
                      ),
                    },
                    {
                      text: 'commercial',
                      label: (
                        <>
                          <FontAwesomeIcon
                            tw="text-yellow-600"
                            icon={faBuilding}
                          />{' '}
                          Commercial
                        </>
                      ),
                    },
                    {
                      text: ' ',
                      label: 'Both',
                    },
                  ],
                  id: 'res_com_filter',
                }}
                column
                callback={value => {
                  updateFilters('types', value)
                }}
              />
              <div>
                <Label
                  for="state_select"
                  tw="text-base font-medium text-gray-800"
                >
                  Filter by State
                </Label>
                <Select
                  id="state_select"
                  tw="rounded-lg border-gray-400"
                  onChange={e => updateFilters('state', e.target.value)}
                >
                  <option value="">All States</option>
                  <option value="AL">Alabama</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="MS">Mississippi</option>
                  <option value="TN">Tennessee</option>
                </Select>
              </div>
            </div>
          </div>
          <div tw=" grid md:(grid-cols-1) col-gap-8 row-gap-8 lg:(col-span-2 grid-cols-2)">
            {loading ? (
              <BranchLoader />
            ) : filteredBranches.length ? (
              filteredBranches.map(branch => (
                <BranchCard
                  tw="border"
                  key={`branch-card-${branch.slug}`}
                  data={branch}
                  showButton
                />
              ))
            ) : (
              <div tw="col-span-2 w-full h-64 flex flex-col items-center justify-center">
                <div tw="text-4xl text-gray-900">No results found.</div>
                <p tw="text-gray-600">Please broaden your search terms.</p>
                <Button onClick={clearSearch} small tw="mt-3">
                  Clear Search
                </Button>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    wp {
      ...WpSeoSchema
    }
    allWpBranch(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        slug
        branchMeta {
          branchOfficeAddress
          branchOfficeCity
          branchOfficeHours
          branchOfficePhone
          branchOfficeState
          branchOfficeZip
        }
        branchTypes {
          nodes {
            slug
          }
        }
      }
    }
  }
`

export default BranchPage
